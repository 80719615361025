*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}
