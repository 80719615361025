.form-wrapper {
  background-color: rgba(0, 0, 0, 0.01);
  border: 20px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.05);
  margin: 20px auto;
  width: 98%;
  max-width: 1500px;
  display: grid;
  grid-template-areas: 'form outputs' 'footer footer';
  grid-template-columns: [first] 50% [second] 50%;
  justify-content: center;
  align-content: center;
}
@media (max-width: 768px) {
  .form-wrapper {
    margin: 0;
    top: 0;
    grid-template-areas: 'form' 'outputs' 'footer';
    grid-template-columns: [first] 100%;
    grid-template-rows: [first] 47% [second] 40% [third] 12% [last-line];
  }
}
